// 
// profile.scss
//

.post-user-comment-box {
    background-color: $gray-100;
    margin: 0 -.75rem;
    padding: 1rem;
    margin-top: 20px;
}

/* cont_profile */
.cont_profile{position:relative; overflow:hidden;}
.cont_profile .left_box{position:relative; float:left; width:40%; text-align:center; background:#fff; border-radius:4px; padding:40px 0; overflow:hidden;}
.cont_profile .left_box .img{width:60px; height:60px; margin:0 auto; overflow:hidden; border-radius:30px;}
.cont_profile .left_box .img img{width:100%; height:100%;}
.cont_profile .left_box .name{font-size:14px; color:#141414; margin:25px 0 5px 0;}
.cont_profile .left_box .text{font-size:13px; color:#333;}
.cont_profile .left_box .mail{font-size:13px; color:#969696;}
.cont_profile .right_box{position:relative; float:right; width:58%; background:#fff; border-radius:4px; overflow:hidden;}
.cont_profile .right_box .name{padding:15px 25px; font-size:13px; color:#333;}
.cont_profile .right_box .mail{border-top:1px solid #f6f6f6; font-size:13px; color:#969696;padding: 15px 25px;}