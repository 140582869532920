.form-signin {
  background-color: $white;
  margin-left: 200px;
  align-items: center;
  height: 100%;
  padding: 15px;
  font-size: large;

  .h3 {
    background-color: $red;
    padding: 10px;
  }
  .checkbox {
    color: blue;
  }
}
