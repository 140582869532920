.content-page {
    margin-left: 240px;
    overflow: visible;
    padding: 0 0 65px 0;
    min-height: 80vh;
    //margin-top: 70px;
}


.content_body{
    margin-left: 10;
    margin-right: 10;
    margin-top: 5;
}

body[data-sidebar-size=condensed] .content-page {
    margin-left: 100px !important;
}

@media (max-width: 991.98px) {

    .content-page,
    body[data-sidebar-size=condensed] .content-page {
        margin-left: 0 !important;
        //padding: 0, 16;
        //margin-top: 56px;

    }
}

.row:before,
.row:after {
    display: inline-block !important;
}