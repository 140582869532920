.tree-card {
    min-height: 640px
}

.tree-item {
	padding: 4px 0px 4px 20px;
    color: #6e768e;
    display: block;
    position: relative;
    transition: all .4s;
    font-size: .875rem;
    i {
	    transition: transform .15s;
    }
}

.tree-item>ul[aria-expanded=true] {
	&i {
	    transform: rotate(90deg);
	}
}

@media (max-width: 991.98px) { 
    .tree-card {
        min-height: auto;
    }
}
