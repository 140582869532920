// 
// general.scss
//

html {
  position: relative;
  min-height: 100%;
}

body {
  overflow-x: hidden;
}
.rbc-active button {
  color: 'green';
  background-color: green;
}
.content_body {
  margin-left: 10px !important;
  margin-right: 10px !important;
  margin-top: 5px !important;
}

html,
body,
div,
h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
ul,
ol,
dl,
dt,
dd,
form {
  margin: 0;
  padding: 0;
  font-family: "Cerebri Sans,sans-serif" !important;
}

body {
  color: #9d9d9d;
  font-size: 12px;
  -webkit-print-color-adjust: exact !important;
}

a,
a:link {
  color: #333;
  text-decoration: none;
}

a:visited {
  color: #333;
}

.search_history:hover {
  opacity: 0.5;
  background-color: #f5f5f5;
  cursor: pointer;
}

a:hover,
a:active,
a:focus {
  color: #333;
  text-decoration: none;
}

img {
  border: 0;
  vertical-align: top;
}

#wrapper {
  height: 100%;
  overflow: hidden;
  width: 100%;
}

.container-fluid {
  max-width: 1600px;
}

/* List 목록 */
ol,
ul,
li {
  list-style: none;
}

/* Text 텍스트 관련 요소 초기화 */
address,
caption,
em,
cite {
  font-weight: normal;
  font-style: normal;
}

/* Border 테두리 없애기 */
fieldset,
img,
abbr,
acronym {
  border: none;
  margin: 0;
  padding: 0;
}

/* Table 테이블 - 마크업에 'cellspacing="0"' 지정 함께 필요 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* hr 수평선*/
hr {
  display: none;
}

//피드백 마우스 오버

.feedback:hover {
  background-color: #F6F6F6;
  transition: 400ms ease-in-out;
}

//피드백 리스트

.loglist_feed {
  display: inline;
  color: #F09A32;
  position: 'relative';
  bottom: 0;
  font-size: 12;
  font-weight: 400;
  margin-top: '3px';
}

.loglist_feed:hover {
  background-color: #F09A32;
  color: #F6F6F6;
  cursor: pointer;
  transition: ease-in-out 300ms;
  font-size: 12;
  font-weight: 400;
}

//니즈 학습 분률 리스트



.needs_list:hover {
  transition: ease-in-out 300ms;
  background-color: #F6F6F6 !important;
}

//캘린더 이월 날짜 색깔

.rbc-day-bg.rbc-off-range-bg{
  background-color: white;
}
.rbc-toolbar-label {
  color: black;
}

.calendar_list{
  cursor: pointer;
}

.calendar_list:hover{
  background-color: #F6F6F6;
}


// .comment_submit:hover{
//   transform: scale(1.05);
// transition: 400ms ease-in-out;
// }

//::-webkit-scrollbar { display: none; }


// ant style override
.ant-modal-confirm-body .ant-modal-confirm-title {
  color: #111111;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.4;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus,
.ant-btn-primary {
  background-color: #111111;
  border-color: #111111;
}

.ant-btn:hover,
.ant-btn:focus,
ant-btn {
  color: #fff;
  background-color: #666666;
  border-color: #666666;
}

//customer item 에도 적용되기 때문에 .wrapper:hover 또한 만져줘야함
//modal item
.MuiListItem-root:hover {
  background-color: #F6F6F6;
  cursor: pointer;
  transition: ease-in-out 300ms;
}

.ant-switch-checked {
  background-color: #398fff;
}


.Mui-selected {
  // background-color: #F6F6F6;
  color: #666
}